<template>
  <div id="numberInputDropdown" class="relative w-full">
    <div ref="containerRef">
      <NumberInput
        v-model="val"
        class="!cursor-pointer z-10"
        :debounce-emit="debounceEmit"
        :wrapper-classes="showDropdown ? 'rounded-b-none' : ''"
        :upper-limit="upperLimit"
        :lower-limit="lowerLimit"
        :step-size="stepSize"
        :is-clickable="true"
        :is-loading="isLoading"
        :is-zero-allowed="isZeroAllowed"
        :hide-buttons="hideButtons"
        :validation-translation-keys="validationTranslationKeys"
        should-validate
        @click.prevent
        @update:model-value="$emit('update:modelValue', $event)"
      >
        <template
          v-if="isZeroAllowed && val === lowerLimit && showTrashIcon"
          #decreaseIcon
        >
          <FaIcon icon-class="fal fa-trash" class="mx-0 sm:mx-2xs" />
        </template>
        <template #content>
          <div
            class="flex items-center justify-center w-full h-full bg-white"
            @click="toggleVisibility"
          >
            {{ val }}
          </div>
        </template>
      </NumberInput>
    </div>
    <Teleport :to="teleportTo">
      <div
        v-show="showDropdown"
        ref="dropdownRef"
        class="absolute flex flex-col w-full overflow-x-hidden overflow-y-scroll max-h-[175.5px] md:max-h-[370.5px] bg-white rounded-b-sm z-[9999] shadow-[0_0_10px_rgba(0,0,0,0.1)]"
        :style="'width: ' + width + 'px'"
      >
        <div
          v-for="option of dropdownOptions"
          :key="option"
          class="h-[39px] flex shrink-0 justify-center items-center hover:bg-primary-lighter cursor-pointer text-text-base"
          :class="{
            'bg-primary-base text-white hover:text-text-base': option === val,
          }"
          @click="
            (val = option),
              (showDropdown = false),
              $emit('update:modelValue', val)
          "
        >
          {{ option }}
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useElementSize } from '@vueuse/core';
import NumberInput from '~/components/components/shop/inputs/number-input.vue';
import { computePosition } from '@floating-ui/vue';
import FaIcon from '~/components/fa-icon.vue';
import type { PropType } from 'vue';
import { NumberInputValidationStates } from '~/@types/number-input';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  upperLimit: {
    type: Number,
    required: false,
    default: 999,
  },
  lowerLimit: {
    type: Number,
    required: false,
    default: 1,
  },
  stepSize: {
    type: Number,
    required: false,
    default: 1,
  },
  debounceEmit: {
    type: Number,
    required: false,
    default: 0,
  },
  dropdownOptions: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  isZeroAllowed: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideButtons: {
    type: Boolean,
    required: false,
    default: false,
  },
  showTrashIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  validationTranslationKeys: {
    type: Object as PropType<Record<NumberInputValidationStates, string>>,
    required: false,
    default: () => ({
      [NumberInputValidationStates.LOWER_LIMIT_ERROR]:
        'numberInput.validation.lowerLimitError',
      [NumberInputValidationStates.UPPER_LIMIT_ERROR]:
        'numberInput.validation.upperLimitError',
      [NumberInputValidationStates.SUCCESS]: 'numberInput.validation.success',
    }),
  },
  teleportTo: {
    type: String,
    required: false,
    default: 'body',
  },
});

/** Emits */
defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();

const val = ref(props.modelValue);
const showDropdown = ref(false);
const containerRef = ref(null);
const dropdownRef = ref(null);

const { width } = useElementSize(containerRef);

onClickOutside(containerRef, () => {
  showDropdown.value = false;
});

function toggleVisibility() {
  showDropdown.value = !showDropdown.value;
  if (showDropdown.value) {
    calculatePosition();
  }
}

function calculatePosition() {
  computePosition(containerRef.value, dropdownRef.value, {
    placement: 'bottom',
  }).then(({ x, y }) => {
    Object.assign(dropdownRef.value?.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
  });
}

onMounted(() => {
  useResizeObserver(window.document.body, () => {
    showDropdown.value = false;
  });
});
</script>
