<template>
  <div class="w-full">
    <DynamicPopup
      wrapper-classes="w-full"
      arrow-classes="bg-[#ffffff] border-[1px] border-[#EEEEEE]"
      :floating-offset="0"
      :arrow-offset="15"
      :placement="validationAlignment"
      :show-on-hover="false"
      :show-popup="
        validationState !== NumberInputValidationStates.SUCCESS &&
        showPopup &&
        !disabled
      "
    >
      <template #trigger>
        <div
          ref="container"
          class="relative w-full max-w-[167px] select-none"
          :class="{
            'opacity-50 cursor-not-allowed': disabled,
          }"
        >
          <div
            class="flex grow rounded-sm border-1 border-light shadow-[0_2px_0_0_var(--thm-border-light)] h-input-h-md overflow-hidden bg-white"
            :class="[
              {
                'shadow-[var(--thm-primary-light)]':
                  validationState === NumberInputValidationStates.SUCCESS &&
                  showValidation,
                'shadow-[var(--thm-status-danger-base)]':
                  validationState !== NumberInputValidationStates.SUCCESS &&
                  showValidation,
              },
              wrapperClasses,
            ]"
          >
            <div
              v-if="!hideButtons"
              class="min-w-[30px] max-w-[50px] flex grow justify-center items-center cursor-pointer bg-background-base"
              :class="
                isDecreaseBtnDisabled
                  ? 'text-grey-base-opc50 !cursor-not-allowed'
                  : 'text-grey-base'
              "
              @focus="
                setValidationAlignment(ValidationAlignment.BOTTOM_START);
                checkToShowPopup(
                  isDecreaseBtnDisabled ||
                    (isZeroAllowed && inputValue === lowerLimit),
                  NumberInputValidationStates.LOWER_LIMIT_ERROR,
                );
              "
              @mouseenter="
                setValidationAlignment(ValidationAlignment.BOTTOM_START);
                checkToShowPopup(
                  isDecreaseBtnDisabled ||
                    (isZeroAllowed && inputValue === lowerLimit),
                  NumberInputValidationStates.LOWER_LIMIT_ERROR,
                );
              "
              @blur="
                resetValidationAlignment();
                validationState = NumberInputValidationStates.SUCCESS;
              "
              @mouseleave="
                resetValidationAlignment();
                validationState = NumberInputValidationStates.SUCCESS;
              "
              @click.prevent="!disableDecreaseButton && decrease()"
            >
              <slot name="decreaseIcon">
                <FaIcon icon-class="fas fa-minus" class="mx-0 sm:mx-2xs" />
              </slot>
            </div>
            <div
              class="w-full min-w-[40px] max-w-[65px] font-text text-text-base overflow-y-clip"
            >
              <slot name="content">
                <input
                  v-model="inputValue"
                  type="text"
                  pattern="\d*"
                  class="relative w-full h-full p-0 text-center border-none noGlobal focus:!ring-0 focus:!ring-offset-0 focus:!shadow-none"
                  min="1"
                  maxlength="3"
                  :disabled="disabled"
                  @change="
                    emitTransformedValue(inputValue);
                    validateInput();
                  "
                  @input="validateInput()"
                  @focus="
                    showValidation = shouldValidate;
                    clearValue();
                  "
                  @blur="resetValue()"
                  @keydown="handleKeydown($event)"
                />
              </slot>
            </div>
            <div
              v-if="!hideButtons"
              class="min-w-[30px] max-w-[50px] flex grow justify-center items-center cursor-pointer bg-background-base"
              :class="
                isIncreaseBtnDisabled
                  ? 'text-grey-base-opc50 !cursor-not-allowed'
                  : 'text-grey-base'
              "
              @mouseenter="
                setValidationAlignment(ValidationAlignment.BOTTOM_END);
                checkToShowPopup(
                  isIncreaseBtnDisabled,
                  NumberInputValidationStates.UPPER_LIMIT_ERROR,
                );
              "
              @focus="
                setValidationAlignment(ValidationAlignment.BOTTOM_END);
                checkToShowPopup(
                  isIncreaseBtnDisabled,
                  NumberInputValidationStates.UPPER_LIMIT_ERROR,
                );
              "
              @mouseleave="
                resetValidationAlignment();
                validationState = NumberInputValidationStates.SUCCESS;
              "
              @blur="
                resetValidationAlignment();
                validationState = NumberInputValidationStates.SUCCESS;
              "
              @click.prevent="!disableIncreaseButton && increase()"
            >
              <FaIcon icon-class="fas fa-plus" class="mx-0 sm:mx-2xs" />
            </div>
          </div>
          <Loader v-show="isLoading" class="z-[5] rounded-sm" />
        </div>
      </template>
      <template #content>
        <div
          v-if="shouldValidate"
          class="px-sm py-xs bg-[#ffffff] leading-[24px] rounded-alt-lg text-sm border-[1px] border-[#EEEEEE] shadow-[0_3px_10px_rgba(0,0,0,0.1)]"
        >
          <p>
            {{ validationMsg }}
          </p>
        </div>
      </template>
    </DynamicPopup>
  </div>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import Loader from '@/components/shop/shoppingcart/loader.vue';
import { onClickOutside } from '@vueuse/core';
import { DynamicPopup } from '@/complib';
import {
  NumberInputValidationStates,
  ValidationAlignment,
} from '~/@types/number-input';
import type { PropType } from 'vue';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  upperLimit: {
    type: Number,
    required: false,
    default: 999,
  },
  lowerLimit: {
    type: Number,
    required: false,
    default: 1,
  },
  stepSize: {
    type: Number,
    required: false,
    default: 1,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  wrapperClasses: {
    type: String,
    required: false,
    default: '',
  },
  shouldValidate: {
    type: Boolean,
    required: false,
    default: false,
  },
  isClickable: {
    type: Boolean,
    required: false,
    default: true,
  },
  isZeroAllowed: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideButtons: {
    type: Boolean,
    required: false,
    default: false,
  },
  debounceEmit: {
    type: Number,
    required: false,
    default: 0,
  },
  disableIncreaseButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableDecreaseButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  validationTranslationKeys: {
    type: Object as PropType<Record<NumberInputValidationStates, string>>,
    required: false,
    default: () => ({
      [NumberInputValidationStates.LOWER_LIMIT_ERROR]:
        'numberInput.validation.lowerLimitError',
      [NumberInputValidationStates.UPPER_LIMIT_ERROR]:
        'numberInput.validation.upperLimitError',
      [NumberInputValidationStates.SUCCESS]: 'numberInput.validation.success',
    }),
  },
});
/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();

const { t } = useTrans();
const container = ref();
const inputValue = ref(props.modelValue);
const validationState = ref<NumberInputValidationStates>(
  NumberInputValidationStates.SUCCESS,
);
const showValidation = ref(false);
const validationAlignment = ref(ValidationAlignment.BOTTOM);
const showPopup = ref(false);
const currentInputValue = ref(0);

watch(
  () => props.modelValue,
  (nv) => {
    if (nv !== inputValue.value) inputValue.value = nv;
  },
);

const debouncedEmit = useDebounceFn(async () => {
  emit('update:modelValue', inputValue.value);
}, props.debounceEmit);

const isDecreaseBtnDisabled = computed(() => {
  return (
    (inputValue.value === props.lowerLimit &&
      !props.isZeroAllowed &&
      props.isClickable) ||
    (inputValue.value === 0 && props.isZeroAllowed && props.isClickable) ||
    props.disableDecreaseButton ||
    props.disabled
  );
});

const isIncreaseBtnDisabled = computed(() => {
  return (
    (inputValue.value === props.upperLimit && props.isClickable) ||
    props.disableIncreaseButton ||
    props.disabled
  );
});

const validationMsg = computed(() => {
  let quantity = props.lowerLimit;
  if (validationState.value === NumberInputValidationStates.UPPER_LIMIT_ERROR) {
    quantity = props.upperLimit;
  }

  return t(props.validationTranslationKeys[validationState.value], {
    quantity: quantity,
  });
});

const decrease = () => {
  if (!props.isClickable || props.disabled) return;

  if (inputValue.value - props.stepSize >= props.lowerLimit) {
    inputValue.value -= props.stepSize;
  } else if (
    props.isZeroAllowed &&
    inputValue.value - props.stepSize < props.lowerLimit
  ) {
    inputValue.value = 0;
  }
  emitTransformedValue(inputValue.value);
};

const increase = () => {
  if (!props.isClickable || props.disabled) return;
  if (inputValue.value + props.stepSize <= props.upperLimit) {
    inputValue.value += props.stepSize;
  }
  emitTransformedValue(inputValue.value);
};

function emitTransformedValue(value: number | string) {
  // Make sure the value is a number
  inputValue.value = +value;
  const val: number = +value;
  if (
    (val < props.lowerLimit && !props.isZeroAllowed) ||
    (val < props.lowerLimit && props.isZeroAllowed && val !== 0)
  ) {
    inputValue.value = props.lowerLimit;
  } else if (val > props.upperLimit) {
    inputValue.value = props.upperLimit;
  }

  if (props.debounceEmit > 0) {
    debouncedEmit();
  } else {
    emit('update:modelValue', inputValue.value);
  }
}

function handleKeydown(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowUp':
      if (inputValue.value + props.stepSize <= props.upperLimit) {
        increase();
      }
      break;
    case 'ArrowDown':
      if (inputValue.value - props.stepSize >= props.lowerLimit) {
        decrease();
      }
      break;
  }
}

function validateInput() {
  if (!props.shouldValidate) {
    return;
  }

  showValidation.value = true;
  // If the input is greater than the upper limit or smaller than the lower limit, it is invalid
  if (
    (inputValue.value < props.lowerLimit && !props.isZeroAllowed) ||
    (inputValue.value < props.lowerLimit &&
      props.isZeroAllowed &&
      inputValue.value !== 0)
  ) {
    showPopup.value = true;
    validationState.value = NumberInputValidationStates.LOWER_LIMIT_ERROR;
    return;
  } else if (inputValue.value > props.upperLimit) {
    showPopup.value = true;
    validationState.value = NumberInputValidationStates.UPPER_LIMIT_ERROR;
    return;
  }

  showPopup.value = false;
  validationState.value = NumberInputValidationStates.SUCCESS;
}

onClickOutside(container, () => {
  showValidation.value = false;
});

function setValidationAlignment(align: ValidationAlignment) {
  validationAlignment.value = align;
}

function resetValidationAlignment() {
  validationAlignment.value = ValidationAlignment.BOTTOM;
  showPopup.value = false;
}

function checkToShowPopup(show: boolean, error: NumberInputValidationStates) {
  if (show) {
    showPopup.value = true;
    validationState.value = error;
    return;
  }

  showPopup.value = false;
}

function clearValue() {
  currentInputValue.value = inputValue.value;
  inputValue.value = null;
}

function resetValue() {
  if (inputValue.value === null || isNaN(inputValue.value)) {
    inputValue.value = currentInputValue.value;
    currentInputValue.value = 0;
  }
}
</script>
