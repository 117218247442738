<template>
  <div>
    <div v-if="stepSize > 1">
      <NumberInputDropdown
        v-model="val"
        :upper-limit="upperLimit"
        :lower-limit="lowerLimit"
        :step-size="stepSize"
        :dropdown-options="options"
        :is-zero-allowed="includeZero"
        :is-loading="isLoading"
        :debounce-emit="debounceEmit"
        :hide-buttons="hideButtons"
        :show-trash-icon="showTrashIcon"
        :disabled="disabled"
        :validation-translation-keys="translationKeys"
        :teleport-to="teleportTo"
        should-validate
        @update:model-value="$emit('update:modelValue', $event)"
      />
    </div>
    <NumberInput
      v-else
      v-model="val"
      :upper-limit="upperLimit"
      :lower-limit="lowerLimit"
      :step-size="stepSize"
      :debounce-emit="debounceEmit"
      :is-loading="isLoading"
      :is-zero-allowed="includeZero"
      :hide-buttons="hideButtons"
      :disable-increase-button="disableIncreaseButton"
      :disabled="disabled"
      :validation-translation-keys="translationKeys"
      should-validate
      @update:model-value="$emit('update:modelValue', $event)"
    >
      <template
        v-if="includeZero && val === lowerLimit && showTrashIcon"
        #decreaseIcon
      >
        <FaIcon icon-class="fal fa-trash" class="mx-0 sm:mx-2xs" />
      </template>
    </NumberInput>
  </div>
</template>

<script setup lang="ts">
import NumberInput from '~/components/components/shop/inputs/number-input.vue';
import NumberInputDropdown from '~/components/components/shop/inputs/number-input-dropdown.vue';
import FaIcon from '~/components/fa-icon.vue';
import { NumberInputValidationStates } from '~/@types/number-input';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  upperLimit: {
    type: Number,
    required: false,
    default: 999,
  },
  lowerLimit: {
    type: Number,
    required: false,
    default: 1,
  },
  stepSize: {
    type: Number,
    required: false,
    default: 1,
  },
  includeZero: {
    type: Boolean,
    required: false,
    default: false,
  },
  debounceEmit: {
    type: Number,
    required: false,
    default: 0,
  },
  hideButtons: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  showTrashIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  disableIncreaseButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  translationKeys: {
    type: Object as PropType<Record<NumberInputValidationStates, string>>,
    required: false,
    default: () => ({
      [NumberInputValidationStates.LOWER_LIMIT_ERROR]:
        'quantityPicker.validation.lowerLimitError',
      [NumberInputValidationStates.UPPER_LIMIT_ERROR]:
        'quantityPicker.validation.upperLimitError',
      [NumberInputValidationStates.SUCCESS]:
        'quantityPicker.validation.success',
    }),
  },
  teleportTo: {
    type: String,
    required: false,
    default: 'body',
  },
});

/** Emits */
defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();

const val = ref(props.modelValue);
const options = ref([]);

onMounted(() => {
  options.value = getCalculatedOptions();
});

watch(
  () => props.modelValue,
  (nv, ov) => {
    if (nv !== ov) val.value = nv;
  },
);

function getCalculatedOptions() {
  if (props.stepSize === 1) return [];

  const steps = [];
  let highestValInArray = 0;

  while (highestValInArray + props.stepSize <= props.upperLimit) {
    highestValInArray = props.lowerLimit + props.stepSize * steps.length;
    steps.push(highestValInArray);
  }

  if (props.includeZero) {
    steps.unshift(0);
  }

  return steps;
}
</script>
